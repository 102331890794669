import * as React from "react"
import $ from 'jquery';
const IndexPage = () => {
  if (typeof document !== "undefined") {
    $( document ).ready(function() {
      $('.content-column-right, .content-column-left').wrapAll('<div class="content-column"></div>');
  });
  }
  
  return (

    <main>
      <title>Home Page</title>
      <div className="content-column-right"> testing 1 </div>
      <div className="content-column-left"> testing 2 </div>
      
    </main>
  )
}

export default IndexPage
